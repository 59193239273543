
import Society from "../../models/societymgmt/Society";
import Starter from "../../models/sportevent/Starter";
import ClubTeamResults from "../../models/sportevent/ClubTeamResults";
import ClubTeamResultService from "../../services/sportevent/ClubTeamResultService";
import StarterService from "../../services/sportevent/StarterService";
import { Component, Vue } from "vue-property-decorator";
import SporteventService from "@/services/sportevent/SporteventService";
import localstore_sportevent from "../../store/localstore_sportevent.module";
import Sportevent from "@/models/sportevent/Sportevent";
import SporteventCompetition from "@/models/sportevent/SporteventCompetition";
import ClubTeamDisciplineResult from "@/models/sportevent/ClubTeamDisciplineResult";
import i18n from "@/plugins/i18n";
import { v4 as uuidv4 } from "uuid";

@Component
export default class TeamScoringComponent extends Vue {
  public starters: Starter[] = [];
  public societies: Society[] = [];
  public clubTeamResults: ClubTeamResults[] = [];
  public teamSporteventCompetitions: SporteventCompetition[] = [];
  public loading = true;

  public fields = [
    {
      key: "society",
      class: "team-result-society",
      sortable: true,
      label: i18n.tc("societymgmt.society"),
    },
    {
      key: "clubTeamDisciplineResults",
      class: "team-result-discipline",
      sortable: true,
      label: "",
    },
  ];

  async mounted(): Promise<void> {
    this.loading = true;
    this.starters = await StarterService.getAll();
    // get unique societies from starters
    this.societies = this.starters
      .map((starter) => starter.startsForSociety)
      .filter((society, index, self) => {
        return (
          index ===
          self.findIndex((s) => s.id === society.id && s.name === society.name)
        );
      });

    SporteventService.getWithApi(
      localstore_sportevent.state.sportevent.id
    ).then(async (item: Sportevent) => {
      const sportevent = new Sportevent(item);
      sportevent.sporteventCompetitions = sportevent.sporteventCompetitions
        .filter((sc) => sc.competition.isTeamCompetition === true)
        .sort((a, b) =>
          a.competition.name > b.competition.name
            ? 1
            : a.competition.name === b.competition.name
            ? a.competition.ageGenderGroup.ageFrom >
              b.competition.ageGenderGroup.ageFrom
              ? 1
              : -1
            : -1
        );
      this.teamSporteventCompetitions = sportevent.sporteventCompetitions.map(
        (c) => new SporteventCompetition(c)
      );
      console.log(this.teamSporteventCompetitions);

      const tmpClubTeamResults =
        await ClubTeamResultService.getAllBySportevent();
      console.log("tmpClubTeamResults", tmpClubTeamResults);
      this.societies.forEach((society: Society) => {
        let clubTeamResult = tmpClubTeamResults.find(
          (t) => t.society.id === society.id
        );

        if (clubTeamResult === undefined) {
          clubTeamResult = new ClubTeamResults();
          clubTeamResult.id = uuidv4();
          clubTeamResult.sportevent = localstore_sportevent.state.sportevent;
          clubTeamResult.society = society;
        }

        this.teamSporteventCompetitions.forEach((tsc) => {
          let clubTeamDisciplineResult =
            clubTeamResult?.clubTeamDisciplineResults.find(
              (d) => d.sporteventCompetition.id === tsc.id
            );
          if (clubTeamDisciplineResult === undefined) {
            clubTeamDisciplineResult = new ClubTeamDisciplineResult();
            clubTeamDisciplineResult.id = uuidv4();
            clubTeamDisciplineResult.sporteventCompetition = tsc;
            clubTeamResult?.clubTeamDisciplineResults.push(
              clubTeamDisciplineResult
            );
          }
        });
        this.clubTeamResults.push(clubTeamResult);
      });
      console.log(this.clubTeamResults);
      this.loading = false;
    });
  }

  async save(): Promise<void> {
    this.loading = true;
    console.log(this.clubTeamResults);
    this.clubTeamResults = await ClubTeamResultService.saveList(
      this.clubTeamResults
    );
    this.loading = false;
  }
}
