import SporteventCompetition from "./SporteventCompetition";

export default class ClubTeamDisciplineResult {
  id?: string;
  sporteventCompetition: SporteventCompetition = new SporteventCompetition();
  points = 0;

  constructor(result?: ClubTeamDisciplineResult) {
    if (result === undefined || result === null) {
      return;
    }
    this.id = result.id;
    this.sporteventCompetition = result.sporteventCompetition;
    this.points = result.points;
  }
}
